import { Injectable } from '@angular/core';
import {
    IAgency,
    IAgencyAddDTO,
    IAgencyDTO,
    IAgencyListItem,
    IAgencyLocalizationAddDTO,
    IAgencyPriceDiscount,
    IAgencyPriceDiscountAdd,
    IAgencyPriceList,
    IAgencyPriceListAdd,
    IAgencySchoolAddDTO,
    IBatchEditPaymentEmail,
    IBatchRoomsAddDTO,
    IRoom,
} from 'apps/early-stage-office/src/app/core/models/agency.interface';
import { ApiClientService } from 'apps/early-stage-office/src/app/core/services/api.service';
import { Observable } from 'rxjs';
import { IResults } from 'apps/early-stage-office/src/app/core/models/results.interface';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class AgenciesService {
    public path: string = '';

    constructor(private api: ApiClientService) {}

    public getPath(franchiseId: number, addon?: string | number, type?: 'localizations' | 'schools'): string {
        if (addon) {
            if (type) {
                return `franchises/${franchiseId}/agencies/${addon}/${type}`;
            } else {
                return `franchises/${franchiseId}/agencies/${addon}`;
            }
        } else {
            return `franchises/${franchiseId}/agencies`;
        }
    }

    public getSchoolPath(agencyId: number) {
        return `${agencyId}/schools`;
    }

    public getLocalizationPath(agencyId: number) {
        return `${agencyId}/localizations`;
    }

    public get(franchiseId: number): Observable<IAgencyListItem[]> {
        return this.api.get<IResults<IAgencyListItem[]>>(this.getPath(franchiseId) + '/plain').pipe(map(response => response.results));
    }

    public getSimplified(franchiseId: number): Observable<IAgency[]> {
        return this.api.get<IResults<IAgencyDTO[]>>(this.getPath(franchiseId) + '/simple').pipe(map(response => response.results.map(a => this.parse(a))));
    }

    public getOne(agencyId: number, franchiseId: number): Observable<IAgency> {
        return this.api.get<IResults<IAgency>>(this.getPath(franchiseId, agencyId)).pipe(map(response => this.parse(response.results)));
    }

    public getPrices(agencyId: number, franchiseId: number): Observable<IAgencyPriceList[]> {
        return this.api.get<IResults<IAgencyPriceList[]>>(this.getPath(franchiseId, agencyId) + '/priceLists').pipe(map(response => response.results));
    }

    public getDiscounts(agencyId: number, franchiseId: number): Observable<IAgencyPriceDiscount[]> {
        return this.api
            .get<IResults<IAgencyPriceDiscount[]>>(this.getPath(franchiseId, agencyId) + '/priceLists/discounts')
            .pipe(map(response => response.results));
    }

    public getIndex(franchiseId: number, localityId: number, districtId?: number): Observable<string> {
        let path = 'franchises/ ' + franchiseId + '/agencies/number/' + localityId;

        if (districtId) {
            path += '/' + districtId;
        }

        return this.api.get<IResults<{ number: string }>>(path).pipe(map(response => response.results?.number));
    }

    public create(data: IAgencyAddDTO, franchiseId: number): Observable<IAgency> {
        return this.api.post<IResults<IAgencyDTO>>(this.getPath(franchiseId), data).pipe(map(response => this.parse(response.results)));
    }

    public edit(data: IAgencyAddDTO, franchiseId: number): Observable<IAgency | void> {
        return this.api.patch<IResults<IAgencyDTO>>(this.getPath(franchiseId, data.id), data).pipe(map(response => this.parse(response.results)));
    }

    public publishWebUrl(id: number, franchiseId: number): Observable<IAgency | void> {
        return this.api.post<IResults<IAgencyDTO>>(this.getPath(franchiseId, id) + '/publish').pipe(map(response => this.parse(response.results)));
    }

    public unpublishWebUrl(id: number, franchiseId: number): Observable<IAgency | void> {
        return this.api.post<IResults<IAgencyDTO>>(this.getPath(franchiseId, id) + '/unpublish').pipe(map(response => this.parse(response.results)));
    }
    public publishGallery(id: number, franchiseId: number): Observable<IAgency | void> {
        return this.api.post<IResults<IAgencyDTO>>(this.getPath(franchiseId, id) + '/gallery/publish').pipe(map(response => this.parse(response.results)));
    }

    public unpublishGallery(id: number, franchiseId: number): Observable<IAgency | void> {
        return this.api.post<IResults<IAgencyDTO>>(this.getPath(franchiseId, id) + '/gallery/unpublish').pipe(map(response => this.parse(response.results)));
    }

    public delete(agencyId: number, franchiseId: number, replaceAgencyId: number): Observable<void> {
        return this.api
            .delete<IResults<IAgency>>(this.getPath(franchiseId) + '/' + agencyId, {
                params: { replaceAgency: replaceAgencyId },
            })
            .pipe(map(() => null));
    }

    public createSchool(data: IAgencySchoolAddDTO, franchiseId: number, agencyId: number): Observable<IAgency> {
        return this.api.post<IResults<IAgencyDTO>>(this.getPath(franchiseId, agencyId, 'schools'), data).pipe(map(response => this.parse(response.results)));
    }

    public editSchool(data: IAgencySchoolAddDTO, franchiseId: number, agencyId: number): Observable<IAgency> {
        return this.api
            .patch<IResults<IAgencyDTO>>(this.getPath(franchiseId, agencyId, 'schools') + '/' + data.id, data)
            .pipe(map(response => this.parse(response.results)));
    }

    public deleteSchool(schoolId: number, franchiseId: number, agencyId: number): Observable<IAgency> {
        return this.api
            .delete<IResults<IAgency>>(this.getPath(franchiseId, this.getSchoolPath(agencyId)) + '/' + schoolId)
            .pipe(map(response => this.parse(response.results)));
    }

    public createLocalization(data: IAgencyLocalizationAddDTO, franchiseId: number, agencyId: number): Observable<IAgency | void> {
        return this.api
            .post<IResults<IAgencyDTO>>(this.getPath(franchiseId, agencyId, 'localizations'), data)
            .pipe(map(response => this.parse(response.results)));
    }

    public editLocalization(data: IAgencyLocalizationAddDTO, franchiseId: number, agencyId: number): Observable<IAgency | void> {
        return this.api
            .patch<IResults<IAgencyDTO>>(this.getPath(franchiseId, agencyId, 'localizations') + '/' + data.id, data)
            .pipe(map(response => this.parse(response.results)));
    }

    public editAgencyPriceList(data: IAgencyPriceListAdd, franchiseId: number, agencyId: number): Observable<IAgencyPriceList[]> {
        const d = {
            priceLists: [data],
        };

        return this.api.patch<IResults<IAgencyPriceList[]>>(this.getPath(franchiseId, agencyId) + '/priceLists', d).pipe(map(response => response.results));
    }

    public editAgencyPriceDiscounts(data: IAgencyPriceDiscountAdd[], franchiseId: number, agencyId: number): Observable<IAgencyPriceDiscount[]> {
        const d = {
            priceListDiscounts: data,
        };

        return this.api
            .patch<IResults<IAgencyPriceDiscount[]>>(this.getPath(franchiseId, agencyId) + '/priceLists/discounts', d)
            .pipe(map(response => response.results));
    }

    public deleteLocalization(localizationId: number, franchiseId: number, agencyId: number): Observable<IAgency | void> {
        return this.api
            .delete<IResults<IAgency>>(this.getPath(franchiseId, this.getLocalizationPath(agencyId)) + '/' + localizationId)
            .pipe(map(response => this.parse(response.results)));
    }

    public createAgencyExportUrl(franchiseId: number, agencyId: number, schoolYearId: number): Observable<IAgencyListItem> {
        return this.api
            .post<IResults<IAgencyListItem>>(this.getPath(franchiseId, agencyId) + '/spreadsheet/' + schoolYearId)
            .pipe(map(response => response.results));
    }

    public refreshAgencyExportUrl(franchiseId: number, agencyId: number, schoolYearId: number): Observable<IAgencyListItem> {
        return this.api
            .post<IResults<IAgencyListItem>>(this.getPath(franchiseId, agencyId) + '/refreshSpreadsheet/' + schoolYearId)
            .pipe(map(response => response.results));
    }

    public deleteAgencyExportUrl(franchiseId: number, agencyId: number, schoolYearId: number): Observable<IAgencyListItem> {
        return this.api
            .delete<IResults<IAgencyListItem>>(this.getPath(franchiseId, agencyId) + '/spreadsheet/' + schoolYearId)
            .pipe(map(response => response.results));
    }

    public batchAddLocalizationRoom(franchiseId: number, agencyId: number, localizationId: number, data: IBatchRoomsAddDTO): Observable<void> {
        return this.api.post<void>(this.getPath(franchiseId, agencyId) + '/localizations/' + localizationId + '/rooms', data);
    }

    public editLocalizationRoom(franchiseId: number, agencyId: number, localizationId: number, room: IRoom): Observable<void> {
        return this.api.patch<void>(this.getPath(franchiseId, agencyId) + '/localizations/' + localizationId + '/rooms/' + room.id, room);
    }

    public deleteLocalizationRoom(franchiseId: number, agencyId: number, localizationId: number, roomId: number): Observable<void> {
        return this.api.delete<void>(this.getPath(franchiseId, agencyId) + '/localizations/' + localizationId + '/rooms/' + roomId);
    }

    public removeSchoolFromLocalization(franchiseId: number, agencyId: number, localizationId: number, schoolId: number): Observable<void> {
        return this.api.delete<void>(this.getPath(franchiseId, agencyId) + '/localizations/' + localizationId + '/schools/' + schoolId);
    }

    public addSchoolToLocalization(franchiseId: number, agencyId: number, localizationId: number, schoolId: number): Observable<void> {
        return this.api.patch<void>(this.getPath(franchiseId, agencyId) + '/localizations/' + localizationId + '/schools', { id: schoolId });
    }

    public addNewSchoolToLocalization(franchiseId: number, agencyId: number, localizationId: number, data: IAgencySchoolAddDTO): Observable<void> {
        return this.api.post<void>(this.getPath(franchiseId, agencyId) + '/localizations/' + localizationId + '/schools', data);
    }

    public batchPaymentEmail(data: IBatchEditPaymentEmail, franchiseId: number): Observable<unknown> {
        return this.api.post<IResults<unknown>>(this.getPath(franchiseId) + '/batchPaymentEmail', data);
    }

    public parse(agency: IAgencyDTO): IAgency {
        return {
            ...agency,
            schools: agency.schools.map(s => {
                return {
                    ...s,
                    hasLocalizations: agency.localizations.some(a => a.agencySchool && a.agencySchool.id === s.id),
                };
            }),
            localizations: agency.localizations.map(l => {
                return {
                    ...l,
                    schools: l.schools?.map(s => {
                        return {
                            ...s,
                            hasLocalizations: agency.localizations.some(a => a.agencySchool && a.agencySchool.id === s.id),
                        };
                    }),
                };
            }),
        };
    }
}
